import React, { ReactNode } from "react";
import moment from "moment";
import { IUserStatus } from "../../../../../types";
import { useTypedSelector } from "../../../../../redux/hooks";
import {
    selectIsUserNewFetch,
    selectStatusApi,
    selectUserStatus
} from "../../../../../redux/slices";
import styles from "../../styles.module.scss";
import FadingText from "../../../../StyledComponents/FadingText";
import LoadingSpinner from "../../../../loading/LoadingSpinner";
import { useAppContext } from '../../../../../context/AppContext/AppContext';

interface BLFIconProps {
    sip_name: string,
}

type BlfStatus = 'in_call' | 'ringing' | 'offline' | 'aux' | 'available' | 'dnd';

const statusCheckerColor = (usersStatus: IUserStatus): BlfStatus => {


    switch (usersStatus.status) {
        case 'open':
            if (usersStatus.global_dnd) {
                return 'dnd'
            }

            if (usersStatus.aux_code?.code && usersStatus.aux_code?.code.toLowerCase() !== 'available') {
                return 'aux'
            }
            return 'available'

        case 'closed':
            if (usersStatus.app.status === 'available') {
                return 'available'
            }
            return 'offline'

        case 'confirmed':
            return 'in_call'

        case 'early':
            return 'ringing'

        default:
            return 'offline'
    }

};

export const BLFIcon: React.FC<BLFIconProps> = ({ sip_name }) => {
    const isFetchUser = useTypedSelector(state => selectIsUserNewFetch(state, sip_name))
    const { isCallSwitch } = useAppContext();

    const status: IUserStatus | undefined = useTypedSelector((state) => selectUserStatus(state, sip_name))
    const blfStatus: BlfStatus = status ? statusCheckerColor(status) : 'offline'

    const apiSetting = useTypedSelector(selectStatusApi);

    let blfPath: ReactNode;
    let dataStrings: string[] = [];

    switch (blfStatus) {
        case "in_call":
        case "ringing":
            blfPath = (<>
                <path
                    d="M9.62451 5.13394C9.62451 7.61922 7.60979 9.63394 5.12451 9.63394C2.63923 9.63394 0.624512 7.61922 0.624512 5.13394C0.624512 2.64866 2.63923 0.633942 5.12451 0.633942C7.60979 0.633942 9.62451 2.64866 9.62451 5.13394Z"
                    stroke="var(--color-danger)"
                />
                <path
                    d="M10.1245 5.13376C10.1245 7.89518 7.88594 10.1338 5.12451 10.1338C2.36309 10.1338 0.124512 7.89518 0.124512 5.13376C0.124512 2.37233 2.36309 0.133759 5.12451 0.133759C7.88594 0.133759 10.1245 2.37233 10.1245 5.13376Z"
                    fill="var(--color-danger)" />
                <path
                    d="M4.10737 6.14379C4.81177 6.85059 5.66898 7.39501 6.3686 7.39501C6.6814 7.39501 6.95599 7.28517 7.17567 7.04161C7.30461 6.89834 7.38341 6.73358 7.38341 6.56882C7.38341 6.44227 7.33326 6.3181 7.21149 6.23214L6.47844 5.7116C6.36144 5.63042 6.26592 5.59221 6.17996 5.59221C6.06774 5.59221 5.96745 5.65429 5.85761 5.76413L5.68569 5.93605C5.65704 5.96232 5.62361 5.97426 5.59257 5.97426C5.55437 5.97426 5.51855 5.95993 5.49467 5.94561C5.34424 5.86442 5.08636 5.64474 4.84758 5.40596C4.6088 5.16718 4.38674 4.91169 4.30794 4.75887C4.29362 4.7326 4.28168 4.69917 4.28168 4.66097C4.28168 4.63231 4.29123 4.59888 4.31749 4.57023L4.48941 4.39592C4.59686 4.28369 4.66133 4.18579 4.66133 4.07356C4.66133 3.98522 4.62313 3.8897 4.54195 3.77509L4.0238 3.0492C3.93545 2.92503 3.81128 2.8725 3.67518 2.8725C3.51281 2.8725 3.35044 2.94413 3.20956 3.08024C2.97079 3.30469 2.86572 3.58406 2.86572 3.89209C2.86572 4.59172 3.40297 5.43939 4.10737 6.14379Z"
                    fill="var(--font-color-white)" />
            </>)
            if (status?.details) {
                dataStrings.push(status.details.remote)
                dataStrings.push(`Since ${moment.unix(parseInt(status.time)).format("HH:mm")}`)
            } else {
                dataStrings.push(blfStatus === 'ringing' ? 'Ringing' : "In Call")
            }
            break;
        case "offline":
            blfPath = (
                <>
                    <path
                        d="M9.62451 5.13394C9.62451 7.61922 7.60979 9.63394 5.12451 9.63394C2.63923 9.63394 0.624512 7.61922 0.624512 5.13394C0.624512 2.64866 2.63923 0.633942 5.12451 0.633942C7.60979 0.633942 9.62451 2.64866 9.62451 5.13394Z"
                        stroke="#424242"
                    />
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M5.12474 4.00082L3.84996 2.72604L2.7168 3.85921L3.99158 5.13399L2.71691 6.40866L3.85007 7.54183L5.12474 6.26716L6.39941 7.54183L7.53258 6.40866L6.25791 5.13399L7.53269 3.85921L6.39952 2.72604L5.12474 4.00082Z"
                        fill="#424242"
                    />
                </>
            )
            dataStrings.push('Unavailable')
            break;
        case "aux":
            blfPath = (<>
                <path
                    d="M9.62451 5.13394C9.62451 7.61922 7.60979 9.63394 5.12451 9.63394C2.63923 9.63394 0.624512 7.61922 0.624512 5.13394C0.624512 2.64866 2.63923 0.633942 5.12451 0.633942C7.60979 0.633942 9.62451 2.64866 9.62451 5.13394Z"
                    stroke="var(--color-danger)"
                />
                <path
                    d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z"
                    fill="var(--color-danger)"
                />
                <path
                    d="M5.97348 6.15843H4.02748L3.70548 7.08943H2.67648L4.43348 2.19643H5.57448L7.33148 7.08943H6.29548L5.97348 6.15843ZM5.70748 5.37443L5.00048 3.33043L4.29348 5.37443H5.70748Z"
                    fill="var(--font-color-white)" />
            </>)
            dataStrings.push(status?.aux_code?.code || '');
            if (status?.aux_code?.time) {
                const aux_time = moment(status.aux_code.time)

                if (aux_time.isBefore(moment().subtract(1, "days"))) {
                    dataStrings.push(`Since ${aux_time.format("ddd HH:mm").toLowerCase()}`)
                } else {
                    dataStrings.push(`Since ${aux_time.format("HH:mm")}`)
                }
            }
            break;
        case "available":
            blfPath = (<>
                <path
                    d="M9.62451 5.13394C9.62451 7.61922 7.60979 9.63394 5.12451 9.63394C2.63923 9.63394 0.624512 7.61922 0.624512 5.13394C0.624512 2.64866 2.63923 0.633942 5.12451 0.633942C7.60979 0.633942 9.62451 2.64866 9.62451 5.13394Z"
                    stroke="var(--color-success)"
                />
                <path
                    d="M10.1245 5.13376C10.1245 7.89518 7.88594 10.1338 5.12451 10.1338C2.36309 10.1338 0.124512 7.89518 0.124512 5.13376C0.124512 2.37233 2.36309 0.133759 5.12451 0.133759C7.88594 0.133759 10.1245 2.37233 10.1245 5.13376Z"
                    fill="var(--color-success)"
                />
                {!isCallSwitch ? (
                    <path
                        d="M4.36335 7.65221L2.13965 5.31386L3.20322 4.19909L4.36335 5.42166L7.04629 2.61484L8.10986 3.72306L4.36335 7.65221Z"
                        fill="var(--font-color-white)"
                    />
                ) : null}
            </>)
            dataStrings.push('Available')
            break;
        case "dnd":
            blfPath = (<>
                <path
                    d="M10 5.25C10 8.01142 7.76142 10.25 5 10.25C2.23858 10.25 0 8.01142 0 5.25C0 2.48858 2.23858 0.25 5 0.25C7.76142 0.25 10 2.48858 10 5.25Z"
                    fill="var(--color-danger)"
                />
                <rect x="2.396" y="4.44849" width="5.20801" height="1.60254" fill="white" />
            </>)
            dataStrings.push("Do Not Disturb")
            break;

    }

    switch (apiSetting) {
        case "fetching":
        case "setup":
            if (apiSetting === 'fetching' && !isFetchUser) break;
            return (
                <div
                    className={styles.blf_icon}
                    data-comp='blf_icon'
                >
                    <div className={styles.glider} />
                    <LoadingSpinner key='blf_loading_spinner' color='mamba' />
                    <p>
                        Loading
                    </p>
                </div>
            )
        case "error":
            blfPath = (
                <>
                    <path
                        d="M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5Z"
                        stroke="#868895" />
                    <path
                        d="M5.74587 1.90243L5.58487 5.24143H4.41587L4.25487 1.90243H5.74587ZM5.00387 7.14543C4.76121 7.14543 4.56521 7.0801 4.41587 6.94943C4.27121 6.8141 4.19887 6.64377 4.19887 6.43843C4.19887 6.2331 4.27121 6.06277 4.41587 5.92743C4.56521 5.7921 4.76121 5.72443 5.00387 5.72443C5.24187 5.72443 5.43321 5.7921 5.57787 5.92743C5.72721 6.06277 5.80187 6.2331 5.80187 6.43843C5.80187 6.6391 5.72721 6.8071 5.57787 6.94243C5.43321 7.07777 5.24187 7.14543 5.00387 7.14543Z"
                        fill="#868895"
                    />
                </>
            )
            dataStrings = ["Error, retrying"]
    }

    return (
        <div
            className={styles.blf_icon}
            data-comp='blf_icon'
        >
            <div className={styles.glider} />
            <svg
                data-early={blfStatus === "ringing" || null}
                width="10"
                height="10"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {blfPath}
            </svg>
            {dataStrings.length === 1 ? (
                <p>
                    {dataStrings[0]}
                </p>
            ) : (
                <FadingText strs={dataStrings} />
            )}
        </div>
    )
}

export default BLFIcon;
