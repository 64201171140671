/* eslint-disable @typescript-eslint/no-explicit-any */
import { CountryCode, E164Number } from 'libphonenumber-js';
import { IHuntGroup, IPhonebookContact } from './auth';
import { InterTenantClient, InterTenantUser } from './call';

export const isUser = (
    user: IUser | IPhonebookContact | IHuntGroup | InterTenantClient | InterTenantUser
): user is IUser => (user as IUser).nickname !== undefined;

export const isPhonebookContact = (
    contact: IUser | IPhonebookContact | IHuntGroup | InterTenantClient
): contact is IPhonebookContact => 'phone_book_uuid' in contact || 'company_name' in contact;

export const isHuntGroup = (
    huntGroup: IUser | IPhonebookContact | IHuntGroup | InterTenantClient
): huntGroup is IHuntGroup => (huntGroup as IHuntGroup)?.members !== undefined;

export const isInterTenantclient = (
    client: IUser | IPhonebookContact | IHuntGroup | InterTenantClient
): client is InterTenantClient => (client as InterTenantClient)?.prefix !== undefined;

export type ThenTypes = 'busy' | 'voicemail' | 'forward';

export type ThenTargetTypes = 'forward' | 'sipuser' | 'huntgroup';

export interface ICallForwarding {
    external_forward_destination: string | E164Number;
    forward_external_calls: boolean;
    forward_internal_calls: boolean;
    internal_forward_destination: string | E164Number;
}

export interface ICallHandlingRoute {
    then: ThenTypes;
    sound_uuid?: string;
    then_target?: string | E164Number;
    then_target_type?: ThenTargetTypes;
}

export interface ICallHandlingData {
    blind_transfer_return_to_sender: boolean;
    is_active: boolean;
    new_when_busy?: ICallHandlingRoute;
    new_no_answer?: ICallHandlingRoute;
    new_when_unreachable?: ICallHandlingRoute;
    route_uuid_when_busy?: string;
    route_uuid_when_no_answer?: string;
    route_uuid_when_unreachable?: string;
}

export interface ICallHandlingPutData {
    call_forwarding?: ICallForwarding;
    call_handling?: ICallHandlingData;
}

export interface ICallHandling {
    call_route_uuid: string;
    is_advanced: boolean;
    then: ThenTypes;
    sound_uuid?: string;
    then_target?: string;
    target_type?: 'sipuser' | 'hungroup' | 'forward';
}

export interface IUser {
    phone_book_uuid: string;
    settings: any;
    allow_direct_calls: boolean;
    allow_aux_codes: boolean;
    allow_custom_aux_codes: boolean;
    allowed_phone_books?: string[];
    avatar_key: string;
    app_allow_aux: boolean;
    app_allow_aux_custom: boolean;
    app_disable_dnd: boolean;
    app_hide_hunt_groups: boolean;
    app_allow_conference?: boolean;
    app_allow_meet?: boolean;
    dashboard_url: string;
    call_encryption: boolean;
    call_forwarding?: ICallForwarding;
    call_handling?: {
        blind_transfer_return_to_sender: boolean;
        is_active: boolean;
        route_uuid_when_busy: string;
        route_uuid_when_no_answer: string;
        route_uuid_when_unreachable: string;
        when_busy_is_advanced: boolean;
        when_no_answer_is_advanced: boolean;
        when_unreachable_is_advanced: boolean;
    };
    call_handling_simple?: {
        WhenBusyCallHandling: ICallHandling;
        WhenNoAnswerCallHandling: ICallHandling;
        WhenUnreachableCallHandling: ICallHandling;
    };
    call_recording: boolean;
    caller_id?: string;
    message_id?: string;
    caller_id_details?: { cli_display: string; uuid: string };
    can_barge: boolean;
    can_be_barged: boolean;
    can_be_listened: boolean;
    can_be_picked_up: boolean;
    can_be_whispered: boolean;
    can_invite_anonymously: boolean;
    can_listen: boolean;
    can_pickup: boolean;
    can_select: boolean;
    can_whisper: boolean;
    global_call_recording: boolean;
    collect_call_feedback: boolean;
    chat_enabled: boolean;
    country_code: CountryCode;
    emergency_caller_id: string;
    extension: number;
    hold_playlist: string;
    mute_chat_notifications: boolean;
    name: string;
    nickname: string;
    password: string;
    personal_mailbox?: string;
    restrict_caller_id: boolean;
    restrict_outbound: boolean;
    restrict_phone_books: boolean;
    ring_duration: number;
    show_missed_calls: boolean;
    timezone: string;
    transfer_mailbox_timeout: number;
    type: number | string;
    use_default_playlist: boolean;
    use_direct_calls: boolean;
    use_mailbox_on_transfer: boolean;
    uuid: string;
    status: {
        uuid: string;
        sipuser: string;
        status: string;
        time: string;
        details: any;
        app: any;
        chat: any;
    };
    chat_config?: {
        can_create_group: boolean;
        can_admin_all_public_groups: boolean;
        can_add_friend: boolean;
    };
    app_version?: number;
    support_phone_number?: string;
    global_settings?: ApiGlobalSetting[];
    additional_mailbox_ids?: string[];
}

export type ApiGlobalSetting =
    | {
          item_type: 1 | 7 | 8;
          data_boolean: boolean;
          data_uuid?: string;
      }
    | {
          item_type: 2 | 3 | 4 | 5 | 6;
          data_boolean?: boolean;
          data_uuid: string;
      };

export interface IChatSettings {
    disableChatNotifications: boolean;
    chatNotificationVolume: number;
    isLargeFont: boolean;
    defaultChatView: 'none' | 'last-open' | 'last-updated';
    lastOpenChatId: string;
    style?: 'default' | 'minimal';
    backgroundColor?: string;
    disableEnter?: boolean;
}

export interface IPhoneSettings {
    currentAppLocale?: string;
    allowInternalCalls: boolean;
    autoAnswerClickToDial: boolean;
    bringAppToFrontOff: boolean;
    callVolumeMute: boolean;
    callVolumeValue: number;
    callWaitingBeepOff: boolean;
    contactsSortByLastName: boolean;
    defaultToGridView: boolean;
    disableHoldToParkCall: boolean;
    disableShortCodesLongPress: boolean;
    dnd: boolean;
    inputDeviceId: string;
    missedCallNotifications: boolean;
    outputDeviceIdCall: string;
    outputDeviceIdRingtone: string;
    crmIntegrationUuids: string[];
    ringtoneVolumeMute: boolean;
    ringtoneVolumeValue: number;
    showCallRouteName: boolean;
    showPhonebookContactCompanyName: boolean;
    videoDeviceId: string;
    outputDeviceNameCall?: string;
    outputDeviceNameRingtone?: string;
    showCallHandling: boolean;
    popParkedCallsTab: boolean;
    popCrm: 'never' | 'new' | 'same';
    disableHuntGroupDisplay: boolean;
    phonebooksInContacts: boolean;
    keepActiveCall: boolean;
    popWhenAnswered: boolean;
    useGlobalDnd: boolean;
    popOnOutbound: boolean;
}

export interface IAppMetaSettings {
    asideWidth: number;
}

export interface IAppSettings {
    chat: {
        settings?: IChatSettings;
    };
    phone: {
        settings?: IPhoneSettings;
    };
    appMeta: {
        filters: AppPageFilters;
        smartDiallerSelection?: SmartDiallerSelected;
        settings?: IAppMetaSettings;
        smartDiallerNumbers?: SmartDiallerNumber[];
    };
}

export interface SmartDiallerSelected {
    type: 'app' | 'registration' | 'number';
    data: string;
    label: string;
}

export interface SmartDiallerNumber {
    name?: string;
    value: string;
    uuid: string;
}

export interface AppPageFilters {
    chatList: string[];
    contactList: string[];
    callHistory: string[];
}

export type AppFilterPage = keyof AppPageFilters;

export function getHardwareLabel(phone: HardwarePhone) {
    if (!phone.device_nickname) {
        return phone.device_name;
    }

    if (phone.device_nickname === phone.device_name) {
        return phone.device_name;
    }

    return `${phone.device_nickname} (${phone.device_name})`;
}

export interface HardwarePhone {
    device_name: string;
    device_nickname: string;
    mac_address: string;
    line_keys: number;
    keys_per_page: number;
    max_pages: number;
    paging_type: number;
    manufacturer_name: string;
}

export interface ILoggedInUser extends IUser {
    settings: IAppSettings;
    allowed_caller_ids?: string[];
    devices?: Record<string, any>[];
    hardware_phones?: HardwarePhone[];
    permissions?: {
        microphone?: string;
        camera?: string;
    };
    can_config_line_keys?: boolean;
    can_create_feedback_tag?: boolean;
}
