import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { LineKey } from './slice';
import { HardwarePhone } from '../../../types';

export const selectLineKeys = (state: RootState) => state.provision.lineKeys;

export const selectChangedPositions = (state: RootState) => state.provision.changedPositions;

export const selectProvisionItem = (state: RootState) => state.provision.provisionItem;

export const selectProvisionItemUuid = (state: RootState): string | undefined =>
    state.provision.provisionItem?.uuid;

export const selectEnhancedLineKeys = createSelector(
    [
        (state: RootState) => state.provision.lineKeys || [],
        (state: RootState) => state.provision.hardware_Config || {},
        (state: RootState) => state.provision.hardwarePhones || [],
        (state: RootState) => state.provision.selectedHardwarePhone,
        (state: RootState) => state.auth.users || []
    ],
    (lineKeys, hardwareConfig, hardwarePhones, selectedHardwarePhone, users) => {

        const hardwareConfigMap = new Map<number, LineKey>();

        if (selectedHardwarePhone) {
            const selectedPhone = hardwarePhones.find(
                (phone) => phone.mac_address === selectedHardwarePhone
            );

            if (selectedPhone?.uuid) {
                const advanceConfig = hardwareConfig[selectedPhone.uuid];
                if (advanceConfig) {
                    advanceConfig.forEach((configItem: LineKey) => {
                        hardwareConfigMap.set(configItem.position, {
                            ...configItem,
                            isHardwareConfig: true,
                        });
                    });
                }
            }
        }

        const mergedLineKeys: LineKey[] = lineKeys.map((lk: LineKey) => {
            const adjustedPosition = lk.position + 1;

            if (hardwareConfigMap.has(adjustedPosition)) {
                const config = hardwareConfigMap.get(adjustedPosition);
                if (config) {
                    return {
                        ...config,
                        position: lk.position,
                    };
                }
            }

            if (!lk.label && lk.value) {
                const matchedUser = users.find(
                    (user) => String(user.extension) === String(lk.value)
                );
                if (matchedUser?.nickname) {
                    return {
                        ...lk,
                        label: matchedUser.nickname,
                    };
                }
            }
            return lk;
        });

        hardwareConfigMap.forEach((config, position) => {
            const finalPosition = position - 1;
            if (!mergedLineKeys.some((lk) => lk.position === finalPosition)) {
                mergedLineKeys.push({
                    ...config,
                    position: finalPosition,
                });
            }
        });

        return mergedLineKeys;
    }
);


export const selectLineKeyByPosition = createSelector(
    [selectLineKeys, (_: any, lineKeyPosition: number) => lineKeyPosition],
    (lineKeys: LineKey[], lineKeyPosition): LineKey | undefined =>
        lineKeys.find(stateLineKey => stateLineKey.position === lineKeyPosition)
);

export const selectApiLineKeys = (state: RootState) => state.provision.apiLineKeys;

export const selectApiLineKeyByPosition = createSelector(
    [selectApiLineKeys, (_: any, lineKeyPosition: number) => lineKeyPosition],
    (lineKeys: LineKey[], lineKeyPosition): LineKey | undefined =>
        lineKeys.find(stateLineKey => stateLineKey.position === lineKeyPosition)
);

const selectAllHardwarePhones = createSelector(
    [(state: RootState) => state.provision.hardwarePhones],
    (phones: HardwarePhone[]) => phones || []
);

export const selectProvisionPhones = createSelector([selectAllHardwarePhones], allPhones =>
    allPhones.filter(p => p.line_keys)
);

export const selectSelectedHardwarePhoneAddress = (state: RootState) =>
    state.provision.selectedHardwarePhone;

export const selectCurrentProvisioningMeta = createSelector(
    [selectAllHardwarePhones, selectSelectedHardwarePhoneAddress],
    (hardwarePhones: HardwarePhone[], currentAddress: string): HardwarePhone | undefined =>
        hardwarePhones?.find(hp => hp.mac_address === currentAddress)
);
