import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    HardwarePhone,
    IHuntGroup,
    ILoggedInUser,
    IPhonebookContact,
    IShortcode,
    IUser
} from '../../../types';
import { authApi } from '../../services/authApi';
import { sipApi } from '../../services/sipApi';

const initialState: ProvisionState = {
    lineKeys: [],
    apiLineKeys: [],
    changedPositions: [],
    hardwarePhones: [],
    selectedHardwarePhone: '',
    hardware_Config:[],
    advancedConfig: {
        blf:[],
        hardware_config:[]
    }
};

interface ProvisionState {
    lineKeys: LineKey[];
    apiLineKeys: LineKey[];
    changedPositions: number[];
    provisionItem?: ProvisionItem;
    hardwarePhones: HardwarePhone[];
    selectedHardwarePhone: string;
    hardware_Config?:  HardwareConfig[]
    advancedConfig?: AdvancedConfig;
}

export type ProvisionItem = IUser | IPhonebookContact | IHuntGroup | IShortcode | undefined;

export interface AdvancedConfig {
    blf:LineKey[];
    hardware_config:HardwareConfig[];
}

export interface LineKey {
    position: number;
    function: LineKeyFunctionType;
    value: string;
    label: string;
    isHardwareConfig?: boolean;
}

export interface HardwareConfig {
    position: number;
    function: LineKeyFunctionType;
    value: string;
    label: string;
    isHardwareConfig?: boolean;
}

export type LineKeyFunctionType = 'blf' | 'speedDial' |'dtmf' | 'park' | undefined;

export const provisionSlice = createSlice({
    name: 'provision',
    initialState,
    reducers: {
        setApiKeys: (state, { payload }: PayloadAction<LineKey[]>) => ({
            ...state,
            apiLineKeys: payload
        }),
        setLineKeys: (state, { payload }: PayloadAction<{ blf: LineKey[] }>) => ({
            ...state,
            lineKeys: payload.blf
        }),
        resetLineKeys: state => ({
            ...state,
            lineKeys: state.apiLineKeys,
            changedPositions: []
        }),
        setAllLineKeys: (state, { payload }: PayloadAction<{ blf: LineKey[]; hardware_config: HardwareConfig[]}>) => {
            const data = payload.blf || [];
            return {
                ...state,
                lineKeys: data,
                apiLineKeys: data,
                hardware_Config: payload.hardware_config
            };
        },
        updateOneLineKey: (state, { payload }: PayloadAction<LineKey>) => ({
            ...state,
            lineKeys: state.lineKeys.some(
                stateLineKey => stateLineKey.position === payload.position
            )
                ? state.lineKeys.map(stateLineKey => {
                      if (stateLineKey.position === payload.position) {
                          return payload;
                      }
                      return stateLineKey;
                  })
                : [...state.lineKeys, payload]
        }),
        deleteOneLineKey: (state, { payload }: PayloadAction<number>) => ({
            ...state,
            lineKeys: state.lineKeys.filter(stateLineKey => stateLineKey.position !== payload)
        }),
        addOnePositionChanged: (state, { payload }: PayloadAction<number>) => ({
            ...state,
            changedPositions: [...state.changedPositions, payload]
        }),
        removeOnePositionChanged: (state, { payload }: PayloadAction<number>) => ({
            ...state,
            changedPositions: state.changedPositions.filter(
                statePosition => statePosition !== payload
            )
        }),
        clearPositionChanged: state => ({
            ...state,
            changedPositions: []
        }),
        setProvisionItem: (state, { payload }: PayloadAction<ProvisionItem>) => ({
            ...state,
            provisionItem: payload
        }),
        setSelectedHardwarePhone: (state, { payload }: PayloadAction<string>) => ({
            ...state,
            selectedHardwarePhone: payload
        })
    },
    extraReducers: builder => {
        builder.addMatcher(
            authApi.endpoints.getUser.matchFulfilled,
            (state, { payload }): ProvisionState => {
                const hardwarePhones = (payload.user as ILoggedInUser)?.hardware_phones || [];
                const selectedHardwarePhone =
                    hardwarePhones.find(p => p.line_keys)?.mac_address || '';

                return {
                    ...state,
                    hardwarePhones,
                    selectedHardwarePhone
                };
            }
        );
        builder.addMatcher(
            sipApi.endpoints.getUserHardware.matchFulfilled,
            (state, { payload }) => ({
                ...state,
                hardwarePhones: payload,
                selectedHardwarePhone:
                    state.selectedHardwarePhone || payload.find(p => p.line_keys)?.mac_address || ''
            })
        );
    }
});

export const provision = provisionSlice.reducer;
