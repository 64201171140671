import { useEffect, useMemo, useState } from 'react';
import {v4 as uuidv4} from "uuid";
import {SessionState} from "sip.js";
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {
    selectAllCalls, selectUsablePhonebooks
} from "../../../redux/slices";
import {useTypedSelector} from "../../../redux/hooks";
import {
    useDeletePhonebookContactMutation,
    usePostPhonebookContactMutation,
    usePutPhonebookContactMutation
} from "../../../redux/services/contactsApi";
import {usePolyglot} from "../../../context/Polyglot";
import {IPhonebookContact} from "../../../types";
import {useContactContext} from "../../../context/ContactContext/context";

type LabelType = 'main' | 'other' | 'work' | 'home' | 'mobile' | 'office';

export const usePhonebookContactEditor = ({closeEditor}: {closeEditor: () => void;}) => {
    const contact = useContactContext().contact as IPhonebookContact;

    const calls = useTypedSelector(selectAllCalls);
    const phonebooks = useTypedSelector(selectUsablePhonebooks);

    const {t} = usePolyglot();

    const [putPhonebookContact, {
        isLoading: putContactLoading,
        isSuccess: putContactSuccess,
        isError: putContactError
    }] = usePutPhonebookContactMutation()
    const [postPhonebookContact, {
        isLoading: postContactLoading,
        isSuccess: postContactSuccess,
        isError: postContactError
    }] = usePostPhonebookContactMutation()
    const [deletePhonebookContact, {
        isLoading: deleteContactLoading,
        isSuccess: deleteContactSuccess,
        isError: deleteContactError
    }] = useDeletePhonebookContactMutation()

    const PhonebookContactFormSchema = object({
        first_name: z.string().min(1, {
            message: t('phrases.error_first_name_required')
        }),
        last_name: z.string().min(1, {
            message: t('phrases.error_last_name_required')
        }),
        company: z.string().optional(),
        phonebook: z.string().min(1, {
            message: t('phrases.error_phonebook_required')
        }),
        phone_numbers: z.record(z.string(), z.object({
            label: z.enum(['main', 'other', 'work', 'home', 'mobile', 'office']),
            phoneNumber: z.string()
                .min(1, {
                    message: t('phrases.error_number_required')
                })
            ,
        })),
        emails: z.record(z.string(), z.object({
            label: z.enum(['main', 'other', 'work', 'home', 'mobile', 'office']),
            emailAddress: z.coerce.string().email({message: t('phrases.error_invalid_email')}).optional(),
        })).optional().default({}),
    });

    type PhonebookContactFormInput = TypeOf<typeof PhonebookContactFormSchema>;

    const formMethods = useForm<PhonebookContactFormInput>({
        resolver: zodResolver(PhonebookContactFormSchema),
        defaultValues: {
            first_name: contact?.first_name || '',
            last_name: contact?.last_name || '',
            company: contact?.company_name || '',
            phonebook: contact?.phone_book_uuid || '',
            phone_numbers:
                contact
                    ? Object.fromEntries(
                        contact.details
                            .filter(detail => detail.type === 1)
                            .map(detail => [
                                uuidv4(),
                                {label: (detail.label.toLowerCase() as LabelType), phoneNumber: detail.value}
                            ])
                    )
                    : {
                        [uuidv4()]: {
                            label: 'main',
                            phoneNumber:
                                calls.find(call => call.state === SessionState.Established)?.callee || ''
                        }
                    },
            emails:
                contact &&
                contact.details.filter(detail => detail.type === 2).length > 0
                    ? Object.fromEntries(
                        contact.details
                            .filter(detail => detail.type === 2)
                            .map(detail => [
                                uuidv4(),
                                {label: (detail.label.toLowerCase() as LabelType), emailAddress: detail.value}
                            ])
                    )
                    : {},
        }
    });

    const saveContact = async (formData: PhonebookContactFormInput) => {

        const parsedPhoneNumbers = Object.keys(formData.phone_numbers).map(key => ({
            type: 1,
            label: formData.phone_numbers[key].label,
            value: formData.phone_numbers[key].phoneNumber
        }));

        const parsedEmailAddresses: { type: number; label: string; value: string; }[] = Object.keys(formData.emails)
            .map(key => ({
                type: 2,
                label: formData.emails[key].label,
                value: formData.emails[key].emailAddress || ''
            }))
            .filter(email => email.value && email.value !== '');
        if (contact?.uuid) {
            putPhonebookContact({
                phonebookUuid: contact.phone_book_uuid,
                contactUuid: contact.uuid,
                data: {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    company_name: formData.company || '',
                    details: [...parsedPhoneNumbers, ...parsedEmailAddresses]
                }
            })
        } else {
            postPhonebookContact({
                phonebookUuid: formData.phonebook,
                data: {
                    first_name: formData.first_name,
                    last_name: formData.last_name,
                    company_name: formData.company || '',
                    details: [...parsedPhoneNumbers, ...parsedEmailAddresses]
                }
            })
        }
    }

    const deleteContact = () => {
        if (contact) {
            deletePhonebookContact({
                phonebookUuid: contact.phone_book_uuid,
                contactUuid: contact.uuid
            })
        }
    };

    useEffect(() => {
        if (putContactSuccess || postContactSuccess || deleteContactSuccess) {
            closeEditor();
        }
    }, [putContactSuccess, postContactSuccess, deleteContactSuccess]);

    const editorMode = contact?.uuid ? 'edit' : 'add'

    const labelOptions = [
        {
            value: 'main',
            label: t("phrases.label_main")
        },
        {
            value: 'home',
            label: t("phrases.label_home")
        },
        {
            value: 'work',
            label: t("phrases.label_work")
        },
        {
            value: 'other',
            label: t("phrases.label_other")
        },
        {
            value: 'mobile',
            label: t("phrases.label_mobile")
        },
        {
            value: 'office',
            label: t("phrases.label_office")
        }
    ];

    const phonebookOptions = useMemo<{ label: string, value: string }[]>(() => phonebooks?.map(phonebook => ({
            value: phonebook.uuid,
            label: `${phonebook.name} (${t('terms.contact_%count', phonebook.total_contacts || 0)})`
        } || []))
        , [phonebooks]);

    return {
        formMethods,
        editorMode,
        labelOptions,
        phonebookOptions,
        saveContact,
        deleteContact,
        actionLoading: putContactLoading || postContactLoading || deleteContactLoading,
        saveError: putContactError || postContactError,
        deleteError: deleteContactError,
    }
}