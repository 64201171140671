import {api} from ".";
import {clearPositionChanged, LineKey, setAllLineKeys, setSpecificKeys} from "../slices";

export const provisionApi = api.injectEndpoints({
    endpoints: builder => ({

        /**
         * GET Requests
         */
        getUserBlfConfig: builder.query<any, string>({
            query: (voip_user_uuid) => ({
                method: 'GET',
                url: `/api/get-user-blf-config`,
                params: {voip_user_uuid}
            }),
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result}} = await queryFulfilled;

                    if (result?.blf === undefined) return;
                    dispatch(
                        setAllLineKeys(result)
                    )

                    dispatch(
                        clearPositionChanged()
                    )
                } catch {
                    // do nothing
                }
            },
        }),


        /**
         * POST Requests
         */

        /**
         * PUT Requests
         */
        putUserBlfConfigure: builder.mutation<{ result: any }, { voip_user_uuid: string; data: LineKey[] }>({
            query: ({ voip_user_uuid, data }) => ({
                method: 'PUT',
                url: '/api/update-user-blf-config',
                params: { voip_user_uuid },
                data: { blf: data },
            }),
            async onQueryStarted({data}, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result}} = await queryFulfilled;

                    if (result === undefined) return;

                    dispatch(
                        setSpecificKeys(data)
                    )
                    dispatch(
                        clearPositionChanged()
                    )
                } catch {
                    // do nothing
                }
            },
        }),

        /**
         * DELETE Requests
         */
    })
})

export const {
    useGetUserBlfConfigQuery,
    usePutUserBlfConfigureMutation,
} = provisionApi;