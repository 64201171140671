import React, {FunctionComponent} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {v4 as uuidv4} from 'uuid';
import AriaLabel from "../../../AriaComponents/AriaLabel";
import FormSelectCombo from "../../../AriaForm/FormSelectCombo";
import {StyledButton} from "../../../StyledComponents";
import {usePolyglot} from "../../../../context/Polyglot";

interface Props {
    base: string;
    label: string;
    inputName: string;
    innerLabel: string;
    labelOptions;
    addMessage: string;
    isPhonenumbers?: boolean;
}

const PhonebookContactEditorMultipleInput: FunctionComponent<Props> = ({
                                                                           base,
                                                                           label,
                                                                           inputName,
                                                                           innerLabel,
                                                                           labelOptions,
                                                                           isPhonenumbers,
                                                                           addMessage
                                                                       }) => {
    const { control, unregister, setValue, getValues } = useFormContext();

    const {t} = usePolyglot();

    const saveCurrentState = () => {
        const currentValues = getValues(base) || {};
        Object.keys(currentValues).forEach(key => {
            setValue(`${base}.${key}`, currentValues[key]);
        });
    };

    return (
        <Controller
            name={base}
            control={control}
            render={({field: {onChange, value}}) => (
                <section>
                    <AriaLabel label={label}/>
                    {isPhonenumbers ? (
                        <mark>*</mark>
                    ) : null}
                    {Object.keys(value).map((key: string, index: number) => (
                        <div key={`PN_${key}`}>
                            <FormSelectCombo
                                label={`${innerLabel}: ${index}`}
                                selectName={`${base}[${key}].label`}
                                inputName={`${base}[${key}].${inputName}`}
                                options={labelOptions}
                                defaultValue='other'
                            />
                            {!isPhonenumbers || Object.keys(value).length > 1 ? (
                                <StyledButton
                                    buttonStyle='text'
                                    context='danger'
                                    type='button'
                                    onClick={() => {
                                        unregister(`${base}.${key}`)
                                        // setNumberKeys(numberKeys.filter(k => k !== key))
                                    }}
                                >
                                    <p>
                                        {t("actions.remove")}
                                    </p>
                                </StyledButton>
                            ) : null}
                        </div>
                    ))}
                    <StyledButton
                        buttonStyle='text'
                        size='small'
                        context='branded'
                        type='button'
                        className='phonebook-contact-editor__add-item'
                        onClick={() => {
                            saveCurrentState();
                            const currentValues = getValues(base) || {};
                            const newValue = {
                                ...currentValues,
                                [uuidv4()]: {
                                    label: Object.keys(currentValues).length === 0 ? 'main' : 'other',
                                    [inputName]: ''
                                }
                            };
                            onChange(newValue);
                        }}
                    >
                        <p>
                            +{addMessage}
                        </p>
                    </StyledButton>
                </section>
            )}
        />
    );
};

export default PhonebookContactEditorMultipleInput;
