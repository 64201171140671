/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { ReactNode, useEffect, useRef } from 'react';
import { CountryCode, isPossiblePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import {
    handleFindingATransfer, selectCallingShortcodes,
    selectContactInBook,
    selectFavouriteContacts,
    selectProvisioningOpen,
    selectProvisionItemUuid,
    selectUserStatus,
    setProvisionItem,
    setViewContact,
    startCall,
    updateFavouriteContact
} from '../../../redux/slices';
import { isHuntGroup, isPhonebookContact, isUser } from '../../../types';
import Icon from '../../Icon';
import DragContainer from '../../DragAndDrop/DragContainer';
import { usePolyglot } from '../../../context/Polyglot';
import { useContactContext } from '../../../context/ContactContext/context';
import { useCallContact } from './hooks';
import { useUserStatusContext } from '../../../pages/home/children/UserStatusContext';
import AriaButton from '../../AriaComponents/AriaButton';
import CallIconButton from '../../buttons/CallIconButton';

type ContactProps = {
    tileView?: boolean;
    icon?: ReactNode;
    menuWalled?: boolean;
    options?: ReactNode;
};

export const getValidNumber = (phoneNumber: string, countryCode: string): string | undefined => {
    let validNumber: string | undefined;

    const parsedMobile = parsePhoneNumberFromString(phoneNumber, countryCode as CountryCode);

    if (parsedMobile && isPossiblePhoneNumber(parsedMobile.number)) {
        validNumber = parsedMobile.number.toString();
    }

    return validNumber;
};

export const Contact: React.FC<ContactProps> = ({ tileView, icon, options, menuWalled }) => {
    const usersForGroupSelection = useTypedSelector(state => state.chat.usersForGroupSelection);
    const showProvisioning = useTypedSelector(selectProvisioningOpen);
    const provisioningOpen = useTypedSelector(selectProvisioningOpen);
    const provisionItemUuid = useTypedSelector(selectProvisionItemUuid);

    const { contact: staticContact, useContactAction } = useContactContext();

    const liveContact = useTypedSelector((state) =>
        selectContactInBook(state, staticContact?.phone_book_uuid, staticContact?.uuid)
    );

    const contact = {
        ...staticContact,
        ...liveContact,
    };

    const { addNameForReq } = useUserStatusContext();

    const userStatus = useTypedSelector(state =>
        isUser(contact) ? selectUserStatus(state, contact.name) : undefined
    );

    const contactRef = useRef<HTMLDivElement>(null);

    const { callContact } = useCallContact();
    const { t } = usePolyglot();

    const userInGroup = usersForGroupSelection.some(u => u.uuid === contact.uuid);

    const favouriteContacts = useTypedSelector(selectFavouriteContacts);
    const showPhonebookContactCompanyName = useTypedSelector(
        state => state.user.settings.phone?.settings?.showPhonebookContactCompanyName
    );
    const { pickupShortcode } = useTypedSelector(selectCallingShortcodes);

    const handleContactClick = () => {
        if (provisioningOpen) {
            dispatch(setProvisionItem(provisionItemUuid === contact.uuid ? undefined : contact));
        } else {
            dispatch(setViewContact(contact));
        }
    };

    const aTransferIsActive = useTypedSelector(handleFindingATransfer);

    const dispatch = useTypedDispatch();

    const isPhoneNumber =
        'type' in contact && contact.type === 'phoneNumber' && 'number' in contact;

    const title = isUser(contact)
        ? `${contact.nickname}${contact.extension ? ` (${contact.extension})` : ''}`
        : isHuntGroup(contact)
        ? `${contact.name}${contact.extension_number ? ` (${contact.extension_number})` : ''}`
        : contact.first_name || contact.last_name
        ? `${contact.first_name || ''} ${contact.last_name || ''}`
        : contact.company_name;

    const handleContextMenu = e => {
        e.preventDefault();
        useContactAction({
            type: 'set_show_options',
            payload: true
        });
    };

    useEffect(() => {
        const returnFunc = () => {
            if (isUser(contact)) {
                addNameForReq(contact.name, false);
            }
        };

        if (!isUser(contact)) return returnFunc;

        addNameForReq(contact.name, true);
        return returnFunc;
    }, []);

    if (tileView) {
        return (
            <DragContainer
                id={contact.uuid}
                extraData={isPhonebookContact(contact) ? contact : undefined}
                // disabled={('type' in contact && contact.type === 'phoneNumber') || (!isUser(contact) && !isHuntGroup(contact))}
                // disabled={isHuntGroup(contact)}
            >
                <div
                    className='contact-card tile-view'
                    title={title}
                    onClick={handleContactClick}
                    onContextMenu={e => handleContextMenu(e)}
                    ref={contactRef}
                    aria-label='Contact Tile'
                    tabIndex={0}
                    data-is-provisioning={showProvisioning || null}
                    data-is-prov-item={provisionItemUuid === contact.uuid || null}
                >
                    <div>
                        <div className={menuWalled ? 'menu_walled' : ''}>
                            {icon}
                            <p style={userInGroup ? { color: '#ffffff' } : {}}>
                                {isPhoneNumber
                                    ? contact.number
                                    : isUser(contact)
                                    ? contact.nickname
                                    : isHuntGroup(contact)
                                    ? contact.name
                                    : contact.first_name || contact.last_name
                                    ? `${contact.first_name || ''} ${contact.last_name || ''}`
                                    : contact.company_name}
                                {isPhonebookContact(contact) &&
                                    showPhonebookContactCompanyName &&
                                    (contact.first_name || contact.last_name) &&
                                    contact.company_name && (
                                        <>
                                            <br />
                                            <span>{contact.company_name}</span>
                                        </>
                                    )}
                            </p>
                        </div>
                    </div>
                    {options}
                </div>
            </DragContainer>
        );
    }

    return (
        <div
            className='contact-card'
            title={title}
            onClick={handleContactClick}
            onContextMenu={e => handleContextMenu(e)}
            ref={contactRef}
            tabIndex={0}
            data-is-prov-item={provisionItemUuid === contact.uuid || null}
        >
            <div>
                <div>
                    <DragContainer
                        id={contact.uuid}
                        extraData={isPhonebookContact(contact) ? contact : undefined}
                        disabled={isHuntGroup(contact)}
                    >
                        {icon}
                    </DragContainer>
                    <p
                        style={{
                            color: userInGroup ? '#ffffff' : undefined
                        }}
                    >
                        {isPhoneNumber
                            ? contact.number
                            : isUser(contact)
                            ? contact.nickname
                            : isHuntGroup(contact)
                            ? contact.name
                            : contact.first_name || contact.last_name
                            ? `${contact.first_name || ''} ${contact.last_name || ''}`
                            : contact.company_name}
                        {isPhonebookContact(contact) &&
                            showPhonebookContactCompanyName &&
                            (contact.first_name || contact.last_name) &&
                            contact.company_name && (
                                <>
                                    <br />
                                    <span>{contact.company_name}</span>
                                </>
                            )}
                    </p>
                </div>
                {!showProvisioning ? (
                    <div className='contact-card__icons'>
                        {!aTransferIsActive && favouriteContacts?.[contact.uuid] && (
                            <AriaButton
                                dataTags={{
                                    'data-icon': 'star'
                                }}
                                title={t('actions.remove_from_favourites')}
                                onClick={() => {
                                    dispatch(
                                        updateFavouriteContact(
                                            contact.uuid,
                                            !favouriteContacts?.[contact.uuid]
                                        )
                                    );
                                }}
                            >
                                <Icon name='star' width={28} height={28} />
                            </AriaButton>
                        )}
                        {isUser(contact) &&
                            userStatus &&
                            userStatus.status === 'early' &&
                            contact.can_be_picked_up &&
                            pickupShortcode && (
                                <AriaButton
                                    title={t('phrases.pick_up_call')}
                                    dataTags={{
                                        'data-icon': 'pick_up'
                                    }}
                                    onClick={() => {
                                        dispatch(
                                            startCall(
                                                `${pickupShortcode?.short_code}${contact.extension}`,
                                                `${t('phrases.pick_up_%name', {
                                                    name: contact.nickname
                                                })}`
                                            )
                                        );
                                    }}
                                >
                                    <Icon name='callOutgoing' width={28} height={28} />
                                </AriaButton>
                            )}
                        {/*
                    TODO- find out what this is and see if needed
                    {isPhoneNumber && !aTransferIsActive ?
                        <button>
                            <i
                                className='icon-status-available--hover'
                                title={t("phrases.presence_available")}
                                onClick={callContact}
                                onKeyDown={event => event.key === 'Enter' && callContact(event)}
                            />
                        </button>
                        : isPhoneNumber && <button>
                        <i className='icon-transfer--hover'/>
                    </button>
                    }
                    */}
                        {isUser(contact) && (
                            <CallIconButton
                                user={contact}
                                handleCall={() => {
                                    callContact();
                                }}
                            />
                        )}
                        {isHuntGroup(contact) && (
                            <CallIconButton
                                handleCall={() => {
                                    callContact();
                                }}
                            />
                        )}
                    </div>
                ) : null}
            </div>
            {!showProvisioning ? options : null}
        </div>
    );
};

export default Contact;
