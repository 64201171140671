import React, { memo, useMemo, useRef, useState } from 'react';
import styles from '../styles.module.scss'
import { getDisplayColor } from "../../../../helpers";
import { UserIconProps } from "../index";
import BLFIcon from "./children/BlfIcon";
import ImageOnlyIcon from "../children/ImageOnlyIcon";

import { useAppContext } from '../../../../context/AppContext/AppContext';

interface InnerSVGProps {
    uuid: string,
    avatar_key?: string,
    avatar_str?: string,
}

const InnerSVG: React.FC<InnerSVGProps> = ({ uuid, avatar_key, avatar_str }) => {
    const { darkMode } = useAppContext();

    return (
        <svg
            width="35" height="35" viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.user_svg}
        >
            {(avatar_str || avatar_key) ? (
                <defs>
                    <pattern id={`${uuid}_avatar`} patternUnits="userSpaceOnUse"
                        width="33"
                        height="33">
                        <image
                            href={avatar_str || `https://storage.googleapis.com/v-profiles/${avatar_key}`}
                            width="33"
                            height="33"
                            preserveAspectRatio='xMaxYMax slice'
                        />
                    </pattern>
                </defs>
            ) : null}
            <path fillRule="evenodd" clipRule="evenodd"
                d="M32.9263 9.39634C32.017 9.82828 30.9999 10.07 29.9263 10.07C26.0603 10.07 22.9263 6.93596 22.9263 3.06997C22.9263 1.99682 23.1678 0.980067 23.5994 0.0710983L6.92649 0.0710983C3.61266 0.0710983 0.92627 2.75749 0.92627 6.07132V26.0709C0.92627 29.3847 3.61266 32.0711 6.92649 32.0711H26.926C30.2399 32.0711 32.9263 29.3847 32.9263 26.0709V9.39634Z"
                fill="#CFCFD5"
                className={getDisplayColor(uuid, true, darkMode)}
            />
            {(avatar_str || avatar_key) ? (
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M32.9263 9.39634C32.017 9.82828 30.9999 10.07 29.9263 10.07C26.0603 10.07 22.9263 6.93596 22.9263 3.06997C22.9263 1.99682 23.1678 0.980067 23.5994 0.0710983L6.92649 0.0710983C3.61266 0.0710983 0.92627 2.75749 0.92627 6.07132V26.0709C0.92627 29.3847 3.61266 32.0711 6.92649 32.0711H26.926C30.2399 32.0711 32.9263 29.3847 32.9263 26.0709V9.39634Z"
                    fill={`url(#${uuid}_avatar)`}
                />
            ) : null}
        </svg>
    )
}

export const UserIcon: React.FC<UserIconProps> = memo(({
    uuid,
    sip_name,
    display_name,
    avatar_key,
    avatar_str,
    blf
}) => {
    const { darkMode } = useAppContext();

    const [imageError, setImageError] = useState<boolean>(false);

    const format = useRef<number>(0);

    const display = useMemo(() => {
        const initials: string = display_name.match(/\b\w/g)
            ?.join('')
            .substring(0, 2) || "";

        if (blf && sip_name) {
            format.current = avatar_key ? 1 : 2;
            return (
                <>
                    <BLFIcon
                        sip_name={sip_name}
                    />
                    <InnerSVG
                        uuid={uuid}
                        avatar_key={avatar_key}
                        avatar_str={avatar_str}
                    />
                    {!(avatar_key || avatar_str) && !imageError ? (
                        <span>{initials}</span>
                    ) : null}
                </>
            )
        }
        if (avatar_key && !imageError) {
            format.current = 3
            return (
                <ImageOnlyIcon
                    avatar_str={avatar_str}
                    avatar_key={avatar_key}
                    alt={display_name}
                    setImageError={setImageError}
                    imgOnly
                />
            )
        }
        format.current = 4
        return (
            <div className={[styles.initials_only, getDisplayColor(uuid, true, darkMode)].join(' ')}>
                <span>{initials}</span>
            </div>
        )
    }, [avatar_key, avatar_str]);

    return (
        <div
            className={styles.user_icon}
            data-format={format.current}
        >
            {display}
        </div>
    );
}, (prevProps, nextProps) => {
    if (prevProps.avatar_key !== nextProps.avatar_key) return false;
    if (prevProps.avatar_str !== nextProps.avatar_str) return false;

    return true;
});

export default UserIcon;
