import React, { lazy, Suspense, useState } from 'react';
import styles from './styles.module.scss';
import StyledTabs from '../../../components/StyledComponents/StyledTabs';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { selectMailboxes, selectNewVoicemailCountOnBox, startCall } from '../../../redux/slices';
import VoicemailSkeleton from './skeletons';
import { MailboxDisplay } from '../../../types';
import { StyledButton } from '../../../components/StyledComponents';
import Icon from '../../../components/Icon';

const MailboxMessages = lazy(() => import('./MailboxMessages'));

interface Props {}

const BoxLabel: React.FC<{ box: MailboxDisplay }> = ({ box }) => {
    const unseenCount = useTypedSelector(state => selectNewVoicemailCountOnBox(state, box.uuid));
    return (
        <p className={styles.tab_node}>
            {box.name}
            {box.is_primary ? <>&nbsp;(Primary)</> : null}
            {unseenCount ? (
                <i className={styles.noti}>{unseenCount > 99 ? '+99' : unseenCount}</i>
            ) : null}
        </p>
    );
};

const VoicemailPage: React.FC<Props> = () => {
    const dispatch = useTypedDispatch();

    const allMailboxes = useTypedSelector(selectMailboxes);

    const [selectedMailbox, setSelectedMailbox] = useState<MailboxDisplay | undefined>(
        allMailboxes?.[0]
    );

    return (
        <div className={styles.voicemail_section}>
            <div className={styles.opts}>
                {selectedMailbox ? (
                <StyledButton
                    buttonStyle='text'
                    onClick={() => {
                        dispatch(startCall(selectedMailbox?.extension.toString(), selectedMailbox.name));
                    }}
                    size='small'
                    className={styles.dialMailboxButton}
                    title={`Dial the '${selectedMailbox.name}' mailbox`}

                >
                    <p>Dial Mailbox</p>
                    <Icon name='phoneCall' className={styles.mailboxIcon} />
                </StyledButton>
                ) : null}
            {allMailboxes.length > 1 ? (
                <StyledTabs
                    options={allMailboxes.map(m => ({
                        value: m.uuid,
                        label: `${m.name}${m.is_primary ? ' (Primary)' : ''}`,
                        node: <BoxLabel box={m} key={m.uuid}/>
                    }))}
                    selectedTab={selectedMailbox?.uuid}
                    setSelectedTab={(u: string) => setSelectedMailbox(allMailboxes.find(m => m.uuid === u))}
                />
                ): null}
            </div>
            <Suspense fallback={<VoicemailSkeleton />}>
                {selectedMailbox ? (
                    <>
                        <MailboxMessages mailbox={selectedMailbox.uuid} key={selectedMailbox.uuid} />
                    </>
                ) : null}
            </Suspense>
        </div>
    );
};

export default VoicemailPage;
